<template>
    <div>
        <v-data-table :headers="headers" :items="items" :header-props="headerProps" :search="searchValue"
            :no-data-text="noDataText" :no-results-text="noResultsText" :item-class="clickableClass"
            :hide-default-footer="disablePagination" :disable-pagination="disablePagination"
            @click:row="item => detailPageFunction && navigateToDetails(item)" mobile-breakpoint="0"
            :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" must-sort>
            <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
                <slot :name="slot" v-bind="props" />
            </template>

            <template v-slot:item.actions="{ item }">
                <v-btn v-if="toggleActiveAction" icon :color="item.active ? 'primary' : 'red'" fab
                    @click.stop.prevent="onToggleItemActiveClick(item)">
                    <v-icon v-if="item.active">mdi-toggle-switch</v-icon>
                    <v-icon v-else>mdi-toggle-switch-off</v-icon>
                </v-btn>
                <v-icon v-if="editOption(item)" small class="mr-2" @click.stop.prevent="$emit('editItem', item)">
                    mdi-pencil
                </v-icon>
                <v-icon v-if="deleteOption(item)" small @click.stop.prevent="$emit('deleteItem', item)">
                    mdi-delete
                </v-icon>
                <!-- Extra task-specific actions slot -->
                <slot name="extra-actions" :item="item" />
            </template>
        </v-data-table>
        <ConfirmationDialog :title="confirmationTitle" :description="confirmationDescription"
            v-model="confirmationDialog" :onConfirm="confirmationOnConfirm" />
    </div>
</template>

<script>
import ConfirmationDialog from "@/components/shared/ConfirmationDialog.vue";

export default {
    name: "DataTable.vue",
    components: {
        ConfirmationDialog
    },
    props: {
        items: {
            type: Array,
            required: true
        },
        headers: {
            type: Array,
            required: true
        },
        noDataText: {
            type: String,
            default: "Geen items gevonden."
        },
        noResultsText: {
            type: String,
            default: "Geen overeenkomende items gevonden."
        },
        searchValue: {
            type: String
        },
        detailPageFunction: {
            type: Function
        },
        toggleActiveWithoutConfirmation: {
            type: Function,
            default: () => true
        },
        toggleActiveAction: {
            type: String
        },
        toggleActivePayloadFunction: {
            type: Function,
        },
        deleteOption: {
            type: Function,
            default: () => false
        },
        editOption: {
            type: Function,
            default: () => false
        },
        activateTitle: {
            type: String,
            default: "Activeren"
        },
        activateDescription: {
            type: String,
            default: "Het activeren van dit item kan ook andere items activeren."
        },
        deactivateTitle: {
            type: String,
            default: "Deactiveren"
        },
        deactivateDescription: {
            type: String,
            default: "Het deactiveren van dit item kan ook andere items deactiveren."
        },
        disablePagination: {
            type: Boolean,
            default: true
        },
        localStorageSortingPrefix: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            headerProps: {
                "sort-icon": "mdi-chevron-down"
            },
            confirmationDialog: false,
            confirmationTitle: null,
            confirmationDescription: null,
            confirmationOnConfirm: null,
            sortBy: null,
            sortDesc: false,
        }
    },
    methods: {
        navigateToDetails(item) {
            this.$router.push(this.detailPageFunction(item));
        },
        clickableClass() {
            return this.detailPageFunction ? 'clickable' : '';
        },
        onToggleItemActiveClick(item) {
            //By default or when the condition is met
            if (this.toggleActiveWithoutConfirmation(item)) {
                this.toggleItemActive(item)
                //When a condition is set and not met
            } else {
                if (item.active) {
                    this.confirmationTitle = this.deactivateTitle
                    this.confirmationDescription = this.deactivateDescription
                    this.confirmationOnConfirm = () => this.toggleItemActive(item)
                } else {
                    this.confirmationTitle = this.activateTitle
                    this.confirmationDescription = this.activateDescription
                    this.confirmationOnConfirm = () => this.toggleItemActive(item)
                }
                this.confirmationDialog = true;
            }
        },
        toggleItemActive(item) {
            this.$store.dispatch(this.toggleActiveAction, this.toggleActivePayloadFunction ? this.toggleActivePayloadFunction(item) : item.id)
        }
    },
    created() {
        // Initialize sortBy and sortDesc (happens after the first computed run)
        this.sortBy = localStorage.getItem(this.fullLocalStorageSortingPrefix + 'sort-by') || this.headers[0]?.value;
        this.sortDesc = JSON.parse(localStorage.getItem(this.fullLocalStorageSortingPrefix + 'sort-desc')) || false;
    },
    computed: {
        fullLocalStorageSortingPrefix() {
            return "datatable#" + this.localStorageSortingPrefix + "#"
        }
    },
    watch: {
        // Watch the sortBy and sortDesc values, when the data table's sorting changes, store them in local storage
        sortBy(newval) {
            localStorage.setItem(this.fullLocalStorageSortingPrefix + 'sort-by', newval)
        },
        sortDesc(newVal) {
            localStorage.setItem(this.fullLocalStorageSortingPrefix + 'sort-desc', newVal)
        },
    }
}
</script>